import { useState, useEffect } from "react";

import Login from "templates/Login";
import Recover from "templates/Recover";
import Contents from "templates/Contents";

import PopUP_Faq from "components/PopUp/Faq";
import PopUp_Color from "components/PopUp/Color";
import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_Schedule from "components/PopUp/Schedule";
import PopUp_PhotoCrop from "components/PopUp/PhotoCrop";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import { GetUserData } from "interface/Users";
import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function App(props) {

    const [ loading, setLoading ] = useState(false); 
    const [ idUser, setUserId ] = useState(GetUserData('id'));
    const [ typePage, setTypePage ] = useState(GetListPag('page'));
    const [ url, setUrl ] = useState(window.location.href.split("/"));

    function OpenFile(type, file){        
        if(file !=''){
            SetModalData('ShowFile', { "type": type, "file": file });
            SetModalState('ShowFile', true);
        }
    }

    function AltAccess(origin, title, type, id, name, status){
        SetModalData('Confirmation', { "origin": origin, "title": title, "type" : type, "id" : id, "name": name, "status": status });
        SetModalState('Confirmation', true);
    }

    function CallbackSuccess(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CheckedPage(){
        if(url[3]){
            let sepInf = url[3].split('=');            
            return <Recover setLoading={ setLoading } code={ sepInf[1] } setUrl={ setUrl } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />;

        }else {
            switch(typePage){
                case "dashboard":
                    return <Contents setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } OpenFile={ OpenFile } AltAccess={ AltAccess } />;
                default:   
                    return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />;
            }
        }
    }        

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
    }, []);

    return(
        <>
            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            {
                CheckedPage()
            }

            <PopUp_Color setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } idUser={ idUser } />            
            <PopUP_Schedule OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } idUser={ idUser } />
            <PopUP_Faq setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } idUser={ idUser } />
            <PopUp_PhotoCrop setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } idUser={ idUser } />
            
            <PopUP_Confirmation setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } idUser={ idUser } />
            <PopUP_ReturnResponse setLoading={ setLoading } idUser={ idUser } />
            <PopUP_ShowFile setLoading={ setLoading } idUser={ idUser } />
        </>
    );
}