import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_ArrowRight, Svg_Course, Svg_Dashboard, Svg_DashboardExit, Svg_Edit, Svg_Faq, Svg_Finance, Svg_MenuHamburguer, Svg_MenuPoint, Svg_Schedule, Svg_Settings, Svg_Site, Svg_Students, Svg_User, Svg_UserDash } from 'services/SvgFile';

import { colorIconSecondary, cookiesRegister, defaultColor, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw, urlSite } from "fixedData";

import Page_Details from "pages/Details";

import Page_AccessDash from "pages/AccessDash";
import Page_AccessDash_Details from "pages/AccessDash/Details";

import Page_Courses from "pages/Course";
import Page_Courses_Details from "pages/Course/Details";
import Page_Classes from "pages/Course/Classes";

import Page_Students from "pages/Students";
import Page_Students_Details from "pages/Students/Details";

import Page_Site from "pages/Site";
import Page_Site_Details from "pages/Site/Details";

import Page_Faq from "pages/Faq";

import Page_Entry from "pages/Entry";
import Page_Entry_Details from "pages/Entry/Details";

import Page_Exit from "pages/Exit";
import Page_Exit_Details from "pages/Exit/Details";

import Page_Settings from "pages/Settings";
import PopUP_Historic from "components/PopUp/Historic";

export default function Contents(props){

    const cookies = new Cookies();
    const [ statusMenu, setStatusMenu ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ] = useState(GetUserData('page'));

    function OpenPage(type, value){        
        SetListPag(type, value);
        OpenCloseMenu(false);
    }

    function AltAccess(origin, type, title, id, name, status){
        SetModalData('Confirmation', { "origin": origin, "type" : type, "title": title, "id" : id, "name": name, "status": status });
        SetModalState('Confirmation', true);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "access_dash":
                return <Page_AccessDash setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "access_dash_details":
                return <Page_AccessDash_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            case "courses":
                return <Page_Courses setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "courses_details":
                return <Page_Courses_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "courses_classe_details":
                return <Page_Classes setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
                
            case "students":
                return <Page_Students setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "students_details":
                return <Page_Students_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            case "site":
                return <Page_Site setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "site_details":
                return <Page_Site_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            
            case "faq":
                return <Page_Faq setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            case "finance_entry":
                return <Page_Entry setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "finance_entry_details":
                return <Page_Entry_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            case "finance_exit":
                return <Page_Exit setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;
            case "finance_exit_details":
                return <Page_Exit_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            case "settings":
                return <Page_Settings setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />;

            default: 
                return <Page_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    function CheckedIcon(){
        if(document.body.clientWidth <= 800){            
            return <Svg_MenuHamburguer className={ statusMenu ? "icons" : "icons icon_close" } color="#2d3c53" />;
        }else {
            return <Svg_ArrowRight className={ statusMenu ? "icons" : "icons icon_close" } color="#2d3c53" />;
        }
    }

    function OpenCloseMenu(status){
        let heightClass = document.getElementById('menu_height');        
        heightClass.style.height = "100%";
        
        if(document.body.clientWidth < 800){
            setStatusMenu(status);
            if(status){ }else {
                setTimeout(() => {
                    heightClass.style.height = "auto";
                }, 400);
            }
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
        
        if(document.body.clientWidth <= 1024){
            setStatusMenu(true);
        }
        if(document.body.clientWidth <= 800){
            setStatusMenu(false);
            document.getElementById('menu_height').style.height = "auto";
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1024){
                setStatusMenu(true);
            }
            if(window.innerWidth <= 800){
                CheckedIcon();
                setStatusMenu(false);
                document.getElementById('menu_height').style.height = "auto";
            }
        });
    }, []);

    return(
        <div className="Contents">

            <a href={ urlSite } target="_blank">
                <div className="show_site">
                    <Svg_Site color="#ffffff" className="icons_site" />
                </div>
            </a>

            <div className={ statusMenu ? "div_menu_opt menu_close" : "div_menu_opt" } id="menu_height">
                <div className={ statusMenu ? "div_logo div_logo_alt_width" : "div_logo" } style={ { backgroundColor: defaultColor } }>
                    <div className={ statusMenu ? "open_or_close_menu alt_position_icons" : "open_or_close_menu" } onClick={ ()=>{ setStatusMenu(!statusMenu); OpenCloseMenu(!statusMenu); } }>
                        {
                            CheckedIcon()
                        }
                    </div>
                    
                    <img alt="logotipo" src="./assets/logo.png" className={ statusMenu ? "logotipo logo_hide" : "logotipo" } />
                </div>

                <div className={ statusMenu ? "show_menu show_menu_alt_width" : "show_menu" } style={ { backgroundColor: defaultColor } }>
                    <div className={ currentPage == "index" || currentPage == "index_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "index"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Dashboard className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Dashboard</div>
                        </div>
                    </div>

                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "access_dash"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_UserDash className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Acesso da dashboard</div>
                        </div>
                    </div>

                    <div className={ currentPage == "courses" || currentPage == "courses_details" || currentPage == "courses_classe_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "courses"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Course className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Cursos</div>
                        </div>
                    </div>

                    <div className={ currentPage == "students" || currentPage == "students_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "students"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Students className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Alunos</div>
                        </div>
                    </div>

                    <div className={ currentPage == "site" || currentPage == "site_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "site"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Site className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Layout da site</div>
                        </div>
                    </div>

                    <div className={ currentPage == "faq" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "faq"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Faq className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Faq</div>
                        </div>
                    </div>

                    <div className={ statusMenu ? "show_menu_dash sub_menu show_menu_dash_": "show_menu_dash sub_menu" }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Finance className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                Financeiro
                            </div>
                        </div>

                        <div className={ currentPage == "finance_entry" || currentPage == "finance_entry_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "finance_entry"); } }>
                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                <div className="div_icons">
                                    <Svg_MenuPoint className="icons_dash" color={ colorIconSecondary } />
                                </div>
                                <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                    Entrada
                                </div>
                            </div>
                        </div>

                        <div className={ currentPage == "finance_exit" || currentPage == "finance_exit_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "finance_exit"); } }>
                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                <div className="div_icons">
                                    <Svg_MenuPoint className="icons_dash" color={ colorIconSecondary } />
                                </div>
                                <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                    Saída
                                </div>
                            </div>
                        </div>

                        {/* 
                        <div className={ currentPage == "finance_balance" || currentPage == "finance_balance_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "finance_balance"); } }>
                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                <div className="div_icons">
                                    <Svg_MenuPoint className="icons_dash" color={ colorIconSecondary } />
                                </div>
                                <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                    Balanço
                                </div>
                            </div>
                        </div> 
                        */}
                    </div>
                        
                    {/* 
                    <div className={ currentPage == "site_schedule" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "site_schedule"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Schedule className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                Agenda
                            </div>
                        </div>
                    </div> 
                    */}

                    <div className={ currentPage == "settings" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "settings"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Settings className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                Configurações
                            </div>
                        </div>
                    </div>

                    <div className={ statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_DashboardExit className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Sair</div>
                        </div>
                    </div>
                </div> 

                <div className={ statusMenu ? "div_inf_profile div_inf_profile_alt_width" : "div_inf_profile" } style={ { backgroundColor: defaultColor } }>
                    <div className="show_name_user">
                        <div className="div_img">
                            {
                                userFile !='' ?
                                <img alt="profile user" src={ userFile } className="show_img_profile" /> :
                                <Svg_User color="#FFFFFF" className="show_img_profile" />
                            }
                        </div>
                        <div className={ statusMenu ? "name_user title_close" : "name_user" }>
                            { userName.split(' ')[0] }
                            {
                                userName.split(' ')[1] ? " " + userName.split(' ')[1] : null
                            }
                        </div>
                        
                        <div className={ statusMenu ? "title_close" : "" }>
                            <Svg_Edit className="icons" color="#ffffff" />
                        </div> 
                       
                    </div>
                </div>               
            </div>

            <div className="div_data_page">
                {
                    CurrentPage()
                }
            </div>
            
            <PopUP_Historic setLoading={ props.setLoading } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } idUser={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } />
        </div>
    )
}