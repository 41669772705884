import { useState, useEffect } from "react";

import './Students.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Cancel, Svg_Delete, Svg_Edit, Svg_User, Svg_UserDisabled } from "services/SvgFile";

import { defaultColor } from "fixedData";

export default function Page_Students(props){

    const [ search, setSearch ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('students'));
    const [ listData, setListData ] = useState(GetDataPage('students'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('students').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.note.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.complement.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.phone.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.street.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.city.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.neighborhood.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('students'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('students', setShowPageData);

        return ()=>{
            UnRegisterDataPage('students', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListData(GetDataPage('students'));
    }, [showPageData]);

    return(
        <div className="page_content page_students">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Alunos" OpenPage={ props.OpenPage } page="students_details" idPage={ 0 } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th style={ { backgroundColor: defaultColor } }>Nome</th>
                            <th className="td_2" width="180" style={ { backgroundColor: defaultColor } }>E-mail</th>
                            <th className="td_1" width="20" style={ { backgroundColor: defaultColor } }>Foto</th>
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td className="td_2">{ elem.email }</td>
                                        <td className="td_1" align="center">
                                            <div>
                                                {
                                                    elem.file !='' ?
                                                    <img data-tooltip-id="show_alert" data-tooltip-content="Visualizar foto" data-tooltip-place="top" alt="profile user" src={ elem.file } className="icon_user" onClick={ ()=>{ props.OpenFile('img', elem.file) } } />
                                                    :
                                                    <Svg_User color="#324d6b" className="icon_user" />
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    props.idUser != elem.id ? 
                                                    <>
                                                        {
                                                            elem.status == 0 ?
                                                            <>
                                                                <div data-tooltip-id="show_alert" data-tooltip-content="Liberar acesso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("students", "reactivate_students", "Liberar acesso", elem.id, elem.name, 1); } }>
                                                                    <Svg_UserDisabled color="#F00000" className="icons"/>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div data-tooltip-id="show_alert" data-tooltip-content="Remover acesso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("students", "disabled_students", "Remover acesso", elem.id, elem.name, 0); } }>
                                                                    <Svg_Cancel color="#F00000" className="icons"/>
                                                                </div>
                                                            </>
                                                        }           
                                                        <div data-tooltip-id="show_alert" data-tooltip-content="Excluir acesso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("students", "delete_data", "Excluir acesso", elem.id, elem.name, 0); } } title="Excluir acesso">
                                                            <Svg_Delete color="#F00000" className="icons"/>
                                                        </div>
                                                    </> : null
                                                }
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar acesso" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "students_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar acesso">
                                                    <Svg_Edit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={ 6 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listData.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}