import { useState, useEffect } from "react";

import './Courses.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Cancel, Svg_Delete, Svg_Edit, Svg_SetaDown, Svg_SetaUp } from "services/SvgFile";

import { defaultColor } from "fixedData";
import { Reg_AltPosition } from "services/Register";

export default function Page_Courses(props){

    const [ search, setSearch ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('courses'));
    
    const [ listDataOn, setListDataOn ] = useState(InitialData(1));
    const [ listDataOff, setListDataOff ] = useState(InitialData(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function InitialData(type){
        if(type === 0){
            const newData = [];
            GetDataPage('courses').map((elem, index)=>{
                if(elem.status === 0){
                    newData.push({ "origin": "courses", "currentPage": "courses_details", "id": elem.id, "title": elem.title });
                }
            });
            return newData;
        }else if(type === 1){
            const newData = GetDataPage('courses').filter(item => item.status == type);
            return newData;
        }
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.status === 1){
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.obs.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(InitialData(1));
        }
        setSearch(value);
    }

    function ChangePosition(type, id, order){
        Reg_AltPosition('courses', "course/" + type, id, order, ()=>{ }, ()=>{ props.CallbackError() });
    }

    useEffect(()=>{
        RegisterDataPage('courses', setShowPageData);

        return ()=>{
            UnRegisterDataPage('courses', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(InitialData(1));
        setListDataOff(InitialData(0));
    }, [showPageData]);

    return(
        <div className="page_content page_courses">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Cursos" OpenPage={ props.OpenPage } page="courses_details" idPage={ 0 } origin="courses" typeHistoric="enable_course" historic={ listDataOff } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th style={ { backgroundColor: defaultColor } }>Título</th>
                            <th width="50" style={ { backgroundColor: defaultColor } }>Order</th>
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>
                                            <div className="show_img">
                                                <div className="">
                                                    <img alt="img fundo" src={ elem.cover != "" ? elem.cover : "./assets/not_file.jpg" } className="img_examplo" />
                                                </div>
                                                <div className="">{ elem.title }</div>
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    index == 0 ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition('up', elem.id + '/' + currentItens[index - 1].id, elem.order_) } }>
                                                        <Svg_SetaUp color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                {
                                                    (index + 1) == currentItens.length ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition('down', elem.id + '/' + currentItens[index + 1].id, elem.order_) } }>
                                                        <Svg_SetaDown color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Desativar curso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("courses", "disabled_course", "Desativar curso", elem.id, elem.title, 0); } }>
                                                    <Svg_Cancel color="#F00000" className="icons"/>
                                                </div>

                                                <div data-tooltip-id="show_alert" data-tooltip-content="Excluir curso" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("courses", "delete_data", "Excluir curso", elem.id, elem.title, 0); } } title="Excluir curso">
                                                    <Svg_Delete color="#F00000" className="icons"/>
                                                </div>

                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar curso" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "courses_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar curso">
                                                    <Svg_Edit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={ 6 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listDataOn.length >= 25 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}