import { useState, useEffect } from "react";

import './Settings.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { phoneMask } from "services/Mask";
import { Reg_AltPosition, Reg_Settings } from "services/Register";
import { Svg_Delete, Svg_Link, Svg_SetaDown, Svg_SetaUp } from "services/SvgFile";

import { colorIcon } from "fixedData";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Settings(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('settings'));    
    const [ nameMenu, setNameMenu ] = useState(GetDataPage('name_menu'));

    const [ listNameMenu, setListNameMenu ] = useState(GetDataPage('name_menu'));

    const [ companyName, setCompanyName ] = useState(InitialData('company_name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ whatsApp, setWhatsApp ] = useState(InitialData('whatsapp'));
    const [ logo, setLogo ] = useState(InitialData('logo'));
    const [ logoStatus, setLogoStatus ] = useState(false);

    const [ facebook, setFacebook ] = useState(InitialData('facebook'));
    const [ instagram, setInstagram ] = useState(InitialData('instagram'));
    const [ youtube, setYoutube ] = useState(InitialData('youtube'));
    const [ linkedin, setLinkedin ] = useState(InitialData('linkedin'));
    
    const [ colorTitle, setColorTitle ] = useState(InitialData('color_title'));
    const [ colorBtn, setColorBtn ] = useState(InitialData('color_btn'));
    const [ colorContact, setColorContact ] = useState(InitialData('color_contact'));

    function InitialData(type){
        return GetDataPage('settings')[type];
    }

    function OpenColor(setValue){
        SetModalData('Color', { "setValue": setValue });
        SetModalState('Color', true);
    }

    /* name_menu */
    function HandleData(type, index, value){
        const newData = [...listNameMenu];
        newData[index][type] = value;
        setListNameMenu(newData);
    }
    function ChangePosition(type, id, order){
        Reg_AltPosition('settings', type, id, order, ()=>{ }, ()=>{ props.CallbackError() });
    }
    /* end */
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Settings(props.idUser, companyName, email, text, whatsApp, logo, facebook, instagram, youtube, linkedin, colorTitle, colorBtn, colorContact, listNameMenu, props.CallbackSuccess, props.CallbackError);
    }
    
    useEffect(()=>{
        RegisterDataPage('settings', setDataPage);
        RegisterDataPage('name_menu', setNameMenu);
        return ()=>{
            UnRegisterDataPage('settings', setDataPage);
            UnRegisterDataPage('name_menu', setNameMenu);
        };
    }, []);

    useEffect(()=>{
        setCompanyName(InitialData('company_name'));
        setEmail(InitialData('email'));
        setText(InitialData('text'))
        setWhatsApp(InitialData('whatsapp'));
        setLogo(InitialData('logo'));
        setLogoStatus(false); 

        setFacebook(InitialData('facebook'));
        setInstagram(InitialData('instagram'));
        setYoutube(InitialData('youtube'));
        setLinkedin(InitialData('linkedin'));
        
        setColorTitle(InitialData('color_title'));
        setColorBtn(InitialData('color_btn'));
        setColorContact(InitialData('color_contact'));
    }, [dataPage]);

    useEffect(()=>{
        setListNameMenu(GetDataPage('name_menu'));
    }, [nameMenu]);

    return(
        <form className="page_content page_settings" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Configurações gerais" OpenPage={ props.OpenPage } page="settings" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Nome do menu</div>
                <div className="list_input_data">
                    {
                        listNameMenu.map((elem, index)=>{
                            return(
                                <div className="div_show_btn" key={ index }>
                                    <Comp_Input index={ index } type="text" name={ elem.name } className="all" setValue={ HandleData } value={ elem.name } input="name" placeholder="Nome do menu..." required={ true } maxLength={ 40 } />

                                    <div className="div_opt_alt">
                                        {
                                            index == 0 ?
                                            <div className="space_div_icon" /> :
                                            <div data-tooltip-id="show_alert" data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition('up', elem.id + '/' + listNameMenu[index - 1].id, elem.order_) } }>
                                                <Svg_SetaUp color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                        {
                                            (index + 1) == listNameMenu.length ?
                                            <div className="space_div_icon" /> :
                                            <div data-tooltip-id="show_alert" data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition('down', elem.id + '/' + listNameMenu[index + 1].id, elem.order_) } }>
                                                <Svg_SetaDown color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    <Tooltip id="show_alert" />
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">Informações para contato</div>
                <div className="list_input_data">
                    <Comp_Input index="not" type="text" name="Nome da empresa" className="" setValue={ setCompanyName } value={ companyName } />
                    
                    <Comp_Input index="not" type="email" name="Email" className="inf_data" setValue={ setEmail } value={ email } />

                    <Comp_Input index="not" type="text" name="WhatsApp" className="phone" setValue={ setWhatsApp } value={ phoneMask(whatsApp) } placeholder="(xx) xxxxx-xxxx" />

                    <Comp_InputFile index="not" setValue={ setLogo } value={ logo } setStatus={ setLogoStatus } status={ logoStatus } OpenFile={ props.OpenFile } thumbnail={ logo } title1="Adicionar logo" title2="Logo adicionado" />
                    
                    <Comp_Textarea index="not" name="Texto" className="" setValue={ setText } value={ text } />
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">Rede social</div>
                <div className="list_input_data">
                    <div className="div_show_btn">
                        <Comp_Input index="not" type="text" name="Facebook" className="all" setValue={ setFacebook } value={ facebook } placeholder="Cole o link aqui..." />
                        {
                            facebook !='' ?
                                <a href={ facebook } className="div_icon" target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            : null
                        }
                    </div>

                    <div className="div_show_btn">
                        <Comp_Input index="not" type="text" name="Instagram" className="all" setValue={ setInstagram } value={ instagram } placeholder="Cole o link aqui..." />
                        {
                            instagram !='' ?
                                <a href={ instagram } className="div_icon" target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            : null
                        }
                    </div>

                    <div className="div_show_btn">
                        <Comp_Input index="not" type="text" name="Youtube" className="all" setValue={ setYoutube } value={ youtube } placeholder="Cole o link aqui..." />
                        {
                            youtube !='' ?
                                <a href={ youtube } className="div_icon" target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            : null
                        }
                    </div>

                    <div className="div_show_btn">
                        <Comp_Input index="not" type="text" name="Linkedin" className="all" setValue={ setLinkedin } value={ linkedin } placeholder="Cole o link aqui..." />
                        {
                            linkedin !='' ?
                                <a href={ linkedin } className="div_icon" target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            : null
                        }
                    </div>
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">Cores</div>
                <div className="list_input_data">
                    <div className="div_color" onClick={ ()=>{ OpenColor(setColorTitle) } } style={ { background: colorTitle } }>
                        Cor dos títulos
                    </div>
                    <div className="div_color" onClick={ ()=>{ OpenColor(setColorBtn) } } style={ { background: colorBtn } }>
                        Cor dos botões
                    </div>
                    <div className="div_color" onClick={ ()=>{ OpenColor(setColorContact) } } style={ { background: colorContact } }>
                        Cor de fundo do menu contato
                    </div>
                </div>
            </div>
        </form>
    );
}